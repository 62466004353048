import { RAFirebaseOptions, } from 'react-admin-firebase';

export const config = {
    apiKey: "AIzaSyAsriNRVvw5gbICcpT_hPsPUPRn7w5xhp0",
    authDomain: "neowshop-run.firebaseapp.com",
    databaseURL: "https://neowshop-run-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "neowshop-run",
    storageBucket: "neowshop-run.appspot.com",
    messagingSenderId: "605625822465",
    appId: "1:605625822465:web:7488d99185c46675e13f00",
    measurementId: "G-6NEYL7HT8R"
};

export const functionLink = "https://europe-west3-neowshop-run.cloudfunctions.net/api";

export const optionsLazyLoad: RAFirebaseOptions = {
    logging: true,
    persistence: 'local',
    dontAddIdFieldToDoc: true,
    lazyLoading: {
        enabled: true,
    },
    firestoreCostsLogger: {
        enabled: true,
    },
};

export const options: RAFirebaseOptions = {
    logging: true,
    persistence: 'local',
    dontAddIdFieldToDoc: true,
    lazyLoading: {
        enabled: false,
    },
    firestoreCostsLogger: {
        enabled: true,
    },
};