import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
    List,
    Filter,
    Button,
    Datagrid,
    TextField,
    TextInput,
    useNotify,
    useRecordContext,
} from "react-admin";
import { functionLink } from "../FIREBASE_CONFIG";


const RegisteredShopsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search store" source="id" alwaysOn />
    </Filter>
);

const PostBulkActionButtons = () => (
    <>
    </>
);

const SendReportButton = ({ onClickHandler, loadingId }) => {
    const record = useRecordContext(); // Get the current record
    if (!record) return null;
    const isLoading = loadingId === record.id;
    return (
        <Button
            disabled={isLoading}
            label={isLoading ? "Sending..." : "Send Report"}
            onClick={() => onClickHandler(record.id)} // Call handler with the id
        />
    );
};


export const StoresReportsList = (props) => {

    const [loadingId, setLoadingId] = React.useState(null);
    const notify = useNotify();


    const SumProductsVisitorsField = () => {
        const record = useRecordContext(); // Get the current record

        if (!record || !record.productsVisitors) return 0;

        // Calculate the sum of productsVisitors values
        const sum = Object.values(record.productsVisitors).reduce((acc, value) => acc + value, 0);
        return <span>{sum}</span>;
    };;

    const onClickHandler = async (id) => {
        setLoadingId(id);
        const raw = JSON.stringify({ reportId: id });
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: raw,
            redirect: 'follow'
        };
        fetch(`${functionLink}/send-report-email`, requestOptions)
            .then(response => response.text())
            .then(result => {
                notify(`Report for store with id ${id} has been successfully sent.`, { type: 'success' });
            })
            .catch(error => {
                notify(`Failed to send report for store with id ${id}.`, { type: 'error' });
            })
            .finally(() => {
                setLoadingId(null);
            });
    };


    return <List {...props} filters={<RegisteredShopsFilter />} bulkActionButtons={<PostBulkActionButtons />} >
        <Datagrid>

            <TextField source="id" />
            <TextField source="storeWebVisitors" emptyText="0" />
            <TextField source="storeAppVisitors" emptyText="0" />
            <SumProductsVisitorsField source="productsVisitors" />
            <SendReportButton label="Send Report" onClickHandler={onClickHandler} loadingId={loadingId} />

        </Datagrid>
    </List>
};