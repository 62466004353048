// in src/AdHeader.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
    Datagrid,
    List,
    Edit,
    SimpleForm,
    TextField,
    ShowButton,
    BooleanField,
    TextInput,
    BooleanInput,
    NumberInput,
    NumberField,
} from "react-admin";


export const AdHeaderList = (props) => (
    <List {...props} >
        <Datagrid>
            {/* <TextField source="id" /> */}
            <TextField source="leftText" />
            <TextField source="centerText" />
            <TextField source="rightText" />
            <TextField source="textColor" />
            <TextField source="bgColor" />
            <NumberField source="fontSize" />
            <BooleanField source="isVisible" />

            <ShowButton label="" />
            {/* <EditButton label="" />
            <DeleteButton label="" redirect={false} /> */}
        </Datagrid>
    </List>
);


export const AdHeaderEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            {/* <TextInput disabled source="id" /> */}
            <TextInput source="leftText" fullWidth />
            <TextInput source="centerText" fullWidth />
            <TextInput source="rightText" fullWidth />
            <TextInput source="textColor" />
            <TextInput source="bgColor" />
            <NumberInput source="fontSize" />
            <BooleanInput source="isVisible" />
        </SimpleForm>
    </Edit>
);