// in src/User.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
    Datagrid,
    List,
    Show,
    Create,
    Edit,
    Filter,
    SimpleShowLayout,
    SimpleForm,
    TextField,
    TextInput,
    ShowButton,
} from "react-admin";

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search phone number" source="phoneNumber" alwaysOn />
    </Filter>
);

const PostBulkActionButtons = () => (
    <>
    </>
);

export const UserList = (props) => (
    <List {...props} filters={<UserFilter />} bulkActionButtons={<PostBulkActionButtons />} >
        <Datagrid>

            <TextField source="id" />
            <TextField source="userName" />
            <TextField source="email" />
            <TextField source="phoneNumber" />
            <ShowButton label="" />
            {/* <EditButton label="" />
            <DeleteButton label="" redirect={false} /> */}
        </Datagrid>
    </List>
);

export const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="userName" />
            <TextField source="email" />
            <TextField source="phoneNumber" />
            <TextField source="city" />
            <TextField source="street" />
        </SimpleShowLayout>
    </Show>
);

export const UserCreate = (props) => (
    <Create {...props} >
        <SimpleForm>
            <TextInput source="id" />
            <TextInput source="name" />
            <TextInput source="age" />
        </SimpleForm>
    </Create>
);

export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput disabled source="createdate" />
            <TextInput disabled source="lastupdate" />
            <TextInput source="name" />
            <TextInput source="age" />
        </SimpleForm>
    </Edit>
);