import { useRecordContext } from 'react-admin';

const CustomIdField = ({ source }) => {
    const record = useRecordContext();
    if (!record || !record[source]) {
        return null;
    }

    const formattedId = record[source].split('-').pop();
    return <span>{formattedId}</span>;
};

export default CustomIdField;