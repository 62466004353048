import CustomIdField from "../components/CustomIdField";
import { BulkDeleteWithConfirmButton, Datagrid, DateField, List, NumberField, ReferenceField, TextField } from "react-admin";

const PostBulkActionButtons = (props) => (
    <>
        <BulkDeleteWithConfirmButton {...props} />
    </>
);

export const PaymentList = props => (
    <List {...props} >
        <Datagrid rowClick="edit" bulkActionButtons={<PostBulkActionButtons />} >
            <CustomIdField source="id" />
            <TextField source="storeId" />
            <TextField source="userId" />
            <CustomIdField source="authorizationIdentifier" />
            <DateField source="paidAt" locales='en-ZA' showTime />
            <ReferenceField source="orderId" reference="orders"><TextField source="id" /></ReferenceField>
            <TextField source="currency" />
            <CustomIdField source="paymentIdentifier" />
            <NumberField source="amount" />
        </Datagrid>
    </List>
);