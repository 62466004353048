import * as React from 'react';
import { AppBar, ToggleThemeButton } from 'react-admin';
import { Typography } from '@mui/material';
import Logo from '../assets/images/logo192.png';

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};


const MyAppBar = props => {
    return (
        <AppBar {...props} color="inherit" toolbar={<ToggleThemeButton />}>
            <Typography
                variant="h6"
                color="inherit"
                sx={styles.title}
                id="react-admin-title"
            />
            <img src={Logo} alt='logo' height={20.475} style={{ padding: 5 }} />
            <p> Neowshop </p>
            <span style={styles.spacer} />
        </AppBar>
    );
};

export default MyAppBar;