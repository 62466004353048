// in src/Campaign.js
import * as React from "react";
// tslint:disable-next-line:no-var-requires
import {
    Datagrid,
    List,
    Create,
    Edit,
    Filter,
    SimpleForm,
    TextField,
    TextInput,
    ShowButton,
    DateTimeInput,
    DateField,
    NumberInput,
    NumberField,
} from "react-admin";

const CampaignFilter = (props) => (
    <Filter {...props}>
        {/* <TextInput label="Search phone number" source="phoneNumber" alwaysOn /> */}
    </Filter>
);

const PostBulkActionButtons = () => (
    <>
    </>
);

export const CampaignList = (props) => (
    <List {...props} filters={<CampaignFilter />} bulkActionButtons={<PostBulkActionButtons />} >
        <Datagrid>
            {/* <TextField source="id" /> */}
            <TextField source="name" />
            <TextField source="discountCode" />
            <TextField source="startAt" />
            <TextField source="endAt" />
            <NumberField source="discountPercent" />
            <TextField source="description" />
            <NumberField source="countUsed" />
            <NumberField source="maxCount" />

            <ShowButton label="" />
            {/* <EditButton label="" />
            <DeleteButton label="" redirect={false} /> */}
        </Datagrid>
    </List>
);

export const CampaignCreate = (props) => (
    <Create {...props} >
        <SimpleForm>
            {/* <TextInput source="id" /> */}
            <TextInput source="name" />
            <TextInput source="discountCode" />
            <DateTimeInput source="startAt" />
            <DateTimeInput source="endAt" />
            <NumberInput source="discountPercent" />
            <NumberInput source="maxCount" />
            <TextInput source="description" fullWidth />
            <TextField source="countUsed" />
            <DateField source="createDate" defaultValue={Date.now()} />
            <DateField source="lastUpdate" defaultValue={Date.now()} />
        </SimpleForm>
    </Create>
);

export const CampaignEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            {/* <TextInput disabled source="id" /> */}
            <TextInput source="name" />
            <TextInput source="discountCode" />
            <TextInput source="startAt" />
            <TextInput source="endAt" />
            <NumberInput source="discountPercent" />
            <TextInput source="description" fullWidth />
            <NumberInput source="maxCount" />
            <TextInput disabled source="createDate" />
            <TextInput disabled source="lastUpdate" />
        </SimpleForm>
    </Edit>
);