import { useEffect, useState } from 'react';
import { formatNumber } from '../utils/function';
import { styled } from '@mui/material/styles';
import { DateInput, useDataProvider, SimpleForm, Toolbar } from "react-admin";
import { Box, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';


const FinancialCalculation = () => {

    const dataProvider = useDataProvider();
    const [results, setResults] = useState([])
    const [endDate, setEndDate] = useState(null)
    const [startDate, setStartDate] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const [filteredData, setFilteredData] = useState([])


    const fetchData = async () => {
        try {
            const response = await dataProvider.getList('payments', {
                filter: {},
                pagination: { page: 1, perPage: 1000 },
                sort: { field: 'id', order: 'DESC' },
            });
            if (response?.data) {
                setResults(response.data);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleFetchData = async () => {
        try {
            const filteredPayments = results.filter((payment) => {
                const paymentDate = new Date(payment.paidAt); // Assuming `paidAt` is the date field in the payment
                return paymentDate >= new Date(startDate) && paymentDate <= new Date(endDate).setHours(23, 59, 59, 999);
            });
            const groupedResults = filteredPayments.reduce((acc, payment) => {
                const { storeId, amount } = payment;
                if (!acc[storeId]) {
                    acc[storeId] = { total: 0, count: 0 };
                }
                acc[storeId].total += amount;
                acc[storeId].count += 1;
                return acc;
            }, {});

            const resultsArray = Object.keys(groupedResults).map((storeId) => ({
                storeId,
                total: groupedResults[storeId].total,
                itemCount: groupedResults[storeId].count,
                totalAfterDeduction: groupedResults[storeId].total * 0.95,
            }));
            // console.log('resultsArray: ', resultsArray);
            setFilteredData(resultsArray);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            // setLoading(false);
        }
    };


    useEffect(() => {
        fetchData()
    }, [])


    const CustomToolbar = ({ handleFetchData, ...props }) => (
        <Toolbar {...props}>
            <Button
                loading={true}
                loadingIndicator="Loading…"
                variant="contained"
                color="primary"
                // startIcon={<FetchIco />}
                onClick={handleFetchData}

            >Apply
            </Button>
        </Toolbar>
    );


    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        backgroundColor: theme.palette.action.hover,
    }));


    return (
        <Box>
            <Box flexDirection={'row'} display="grid" gap={2} mb={2}>
                <Typography variant="h6">Financial Calculations</Typography>
                <SimpleForm flexDirection={'row'} gap={2} display={'flex'} toolbar={<CustomToolbar handleFetchData={handleFetchData} />}>
                    <DateInput
                        label="Start Date"
                        source="startDate"
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <DateInput
                        label="End Date"
                        source="endDate"
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    {/* <Button
                        label="Calculate"
                        onClick={handleFetchData}
                    // disabled={!startDate || !endDate}
                    /> */}
                </SimpleForm>

            </Box>

            <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>Store ID</TableCell>
                        <TableCell>Count</TableCell>
                        <TableCell>Total Sales</TableCell>
                        <TableCell>Total After 5% Deduction</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {filteredData.map((result, index) => (
                        <TableRow key={result.storeId}>
                            <TableCell>{index + 1}. {result.storeId}</TableCell>
                            <TableCell>{result.itemCount}</TableCell>
                            <TableCell>{formatNumber(result.total)}</TableCell>
                            <TableCell>{formatNumber(result.totalAfterDeduction)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>

                <StyledTableRow>
                    <TableCell>Total</TableCell>
                    <TableCell>
                        {filteredData.reduce((sum, result) => sum + result.itemCount, 0)}
                    </TableCell>
                    <TableCell>
                        {formatNumber(filteredData.reduce((sum, result) => sum + result.total, 0))}
                    </TableCell>
                    <TableCell>
                        {formatNumber(filteredData.reduce((sum, result) => sum + result.totalAfterDeduction, 0))}
                    </TableCell>
                </StyledTableRow>

            </Table>
        </Box>
    );
};

export default FinancialCalculation;