// import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useState, useEffect } from "react";
import { Title } from "react-admin";

import { purple, green, pink, blue } from '@mui/material/colors';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { functionLink } from "../FIREBASE_CONFIG";
import CardHeader from '@mui/material/CardHeader';
import Store from "@mui/icons-material/Store";
import Shop from "@mui/icons-material/Shop";
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';


const styles = {
    root: {
        display: "flex",
        flexWrap: "wrap",
        "& > *": {
            margin: 3,
        },
    },
    purple: {
        color: '#fff',
        backgroundColor: purple[500],
    },
    green: {
        color: '#fff',
        backgroundColor: green[500],
    },
    pink: {
        color: '#fff',
        backgroundColor: pink[500],
    },
    blue: {
        color: '#fff',
        backgroundColor: blue[500],
    },
};


function Dashboard() {

    // const [isLoading, setIsLoading] = useState(true);
    const [variant, setVariant] = useState("body1");
    const [size, setSize] = useState({
        stores: 'loading...',
        products: 'loading...',
        orders: 'loading...',
        users: 'loading...',
    });

    // console.log('rrreee')
    // dataProvider.getList("orders", { pagination: { page: 0, perPage: 0 }, sort: { field: '', order: '' }, filter: {}, }).then(function (querySnapshot) {
    //     console.log('rrr', querySnapshot);
    // }).catch((e) => console.log('catch: ', e));

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };
        fetch(`${functionLink}/get-size`, requestOptions)
            .then(response => response.text())
            .then(result => {
                setSize(JSON.parse(result));
                // setIsLoading(false);
                setVariant("h4");
            })
            .catch(error => console.log('error', error));
    }, []);

    return <>
        <Card>
            <Title title="Dashboard" />
            <CardContent>
                <Typography variant="h4"> Analystics</Typography>
            </CardContent>
            <CardContent>collection of widget that give you an overview of metrics</CardContent>
        </Card>
        <Box sx={styles.root}>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar sx={styles.green}>
                            <Store />
                        </Avatar>
                    }
                    title="Stores"
                    subheader="All stores"
                    action={
                        <IconButton>
                            <MoreVertIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <Typography variant={variant}>{size.stores}</Typography>
                </CardContent>
                <CardActions>
                    {/* <Button>text</Button> */}
                </CardActions>
            </Card>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar sx={styles.pink}>
                            <AssignmentIcon />
                        </Avatar>
                    }
                    title="Products"
                    subheader="All products"
                    action={
                        <IconButton>
                            <MoreVertIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <Typography variant={variant}>{size.products}</Typography>
                </CardContent>
                <CardActions>
                    {/* <Button>text</Button> */}
                </CardActions>
            </Card>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar sx={styles.blue}>
                            <Shop />
                        </Avatar>
                    }
                    title="Orders"
                    subheader="All orders"
                    action={
                        <IconButton>
                            <MoreVertIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <Typography variant={variant}>{size.orders}</Typography>
                </CardContent>
                <CardActions>
                    {/* <Button>text</Button> */}
                </CardActions>
            </Card>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar sx={styles.purple}>
                            <Shop />
                        </Avatar>
                    }
                    title="users"
                    subheader="All users"
                    action={
                        <IconButton>
                            <MoreVertIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <Typography variant={variant}>{size.users}</Typography>
                </CardContent>
                <CardActions>
                    {/* <Button>text</Button> */}
                </CardActions>
            </Card>
        </Box>
    </>
}

export default Dashboard;