import * as React from "react";
import {
    Datagrid,
    List,
    Show,
    Create,
    Edit,
    DateField,
    ImageField,
    ImageInput,
    SimpleShowLayout,
    SimpleForm,
    TextField,
    TextInput,
    ShowButton,
    EditButton,
    ReferenceField,
    SelectInput,
    ReferenceInput,
    FileInput,
    FileField,
    ArrayInput,
    SimpleFormIterator,
    DateInput,
    NumberField,
    BooleanField,
    Toolbar,
    NumberInput,
    SaveButton,
    useEditController,
    useUpdateMany,
} from "react-admin";
// import RichTextInput from "ra-input-rich-text";
import { FirebaseReferenceField, FirebaseReferenceInput } from '../components/FirebaseReferenceFields';
import { FirebaseDataProvider } from "react-admin-firebase";
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

const { config, options } = require("../FIREBASE_CONFIG");

// const StoreFilter = (props) => (
//   <Filter {...props}>
//     <TextInput label="Search" source="title" alwaysOn />
//   </Filter>
// );

// const ReferenceFilter = (props) => (
//   <Filter {...props}>
//     <ReferenceInput
//       label="Organization"
//       source="user.id"
//       reference="users"
//       allowEmpty
//     >
//       <SelectInput optionText="name" />
//     </ReferenceInput>
//   </Filter>
// );


const PostBulkActionButtons = () => (
    <>
        {/* <ResetViewsButton label="Reset Views" /> */}
        {/* default bulk delete action */}
        {/* <BulkDeleteButton disabled={true} label='ddd' /> */}
    </>
);

export const StoreList = (props) => {

    const navigate = useNavigate();

    const redirectToProducts = (storeId) => {
        navigate(`/products?filter=${encodeURIComponent(JSON.stringify({ storeId }))}`);
    };

    return (
        <List {...props} bulkActionButtons={<PostBulkActionButtons />}>
            <Datagrid rowClick={(id) => redirectToProducts(id)}>
                <EditButton label="" />
                <ShowButton label="" />
                <TextField source="id" />
                <NumberField source="status" />
                <TextField source="storeName" />
                <TextField source="user.name" />
                <TextField source="email" />
                <NumberField source="visitCount" />
                <BooleanField source="termsAndCondition" label={'T & C'} />
                <BooleanField source="payShipping" />
                {/* <NumberField source="updateDate.seconds" /> */}
                <BooleanField source="newsLetter" />
                <DateField source="createAt" locales='en-ZA' />
                <DateField source="updateAt" locales='en-ZA' />

                {/* <RichTextField source="body" /> */}
                {/* <ReferenceField label="User Ref" source="user_ref.___refid" reference="users">
                    <TextField source="name" />
                </ReferenceField> */}

                {/* <DeleteButton label="" redirect={false} /> */}
            </Datagrid>
        </List>
    )
};

// const ConditionalEmailField = ({}) =>
//   record && record.hasEmail ? (
//     <EmailField source="email" record={record} {...rest} />
//   ) : null;
export const StoreShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" />
            <NumberField source="status" />
            <TextField source="storeName" />
            <TextField source="user.name" />
            <TextField source="phone" />
            <TextField source="mobile" />
            <TextField source="city" />
            <TextField source="address" />
            <TextField source="cvr" />
            <TextField source="StoreCode" />
            <NumberField source="visitCount" />
            <BooleanField source="termsAndCondition" label={'T & C'} />
            <BooleanField source="payShipping" />
            {/* <NumberField source="updateDate.seconds" /> */}
            <TextField source="accountNumber" />
            <TextField source="registeringNumber" />
            <BooleanField source="newsLetter" />
            <DateField source="createAt" />
            <DateField source="updateAt" />

            <ReferenceField label="User Id" source="user_id" reference="users">
                <TextField source="user.name" />
            </ReferenceField>

            <ReferenceField label="User Ref" source="user_ref.___refid" reference="users">
                <TextField source="name" />
            </ReferenceField>
            {/* Or use the easier <FirebaseReferenceField> */}
            <FirebaseReferenceField
                label="User (Reference Doc)"
                source="user_ref"
                reference="users"
            >
                <TextField source="name" />
            </FirebaseReferenceField>

            <FileField
                source="files_multiple.src"
                title="files_multiple.title"
                multiple
            />
        </SimpleShowLayout>
    </Show>
);

export const StoreCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput source="id" />
            <TextInput source="title" />
            {/* <RichTextInput source="body" /> */}
            <DateInput source="date" parse={val => new Date(val)} />
            <ReferenceInput
                label="User Id"
                source="user_id"
                reference="users"
            // filter={{ isAdmin: true }}
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            <ReferenceInput
                label="User Ref"
                source="user_ref.___refid"
                reference="users"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
            {/* Or use the easier <FirebaseReferenceInput> */}
            <FirebaseReferenceInput
                label="User Ref (Firebase)"
                source="user_ref"
                reference="users"
            >
                <SelectInput optionText="name" />
            </FirebaseReferenceInput>
            <FileInput source="files_multiple" multiple label="Files with (multiple)">
                <FileField source="src" title="title" />
            </FileInput>
            <ArrayInput source="files">
                <SimpleFormIterator>
                    <FileInput source="file" label="Array Form Files">
                        <FileField source="src" title="title" />
                    </FileInput>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="sections.mySection.items" label="Section items">
                <SimpleFormIterator>
                    <TextInput source="name" label="Name" />
                    <ImageInput source="image" label="Image" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);


const dataProvider = FirebaseDataProvider(config, options);

const MyButton = ({ record }) => {

    let ids = []
    dataProvider.getManyReference('products', {
        target: 'storeId', id: record.id,
        pagination: {
            page: 1,
            perPage: 100
        }
    }).then((i) => {
        i.data.map(a => {
            return ids.push(a.id)
        });
    });

    const { mutate, isLoading } = useMutation(
        ['stores', 'update', { id: record.id, data: { storeStatus: record.status } }],
        () => dataProvider.update('stores', { id: record.id, data: { status: record.status === 1 ? 2 : 1 } })
    );

    const [updateMany] = useUpdateMany();
    const handleClick = () => {
        updateMany('products', { ids: ids, data: { storeStatus: record.status === 1 ? 2 : 1 } });
    };

    return <SaveButton onClick={() => { mutate(); handleClick() }} disabled={isLoading} />;
};

const UserEditToolbar = props => {
    const controllerProps = useEditController(props);
    const { record } = controllerProps;
    return <Toolbar {...props} >
        <MyButton record={record} />
    </Toolbar>
};


export const StoreEdit = (props) => (

    <Edit {...props}>
        <SimpleForm toolbar={<UserEditToolbar />}>
            <TextInput disabled source="id" />
            <TextInput disabled source="storeName" />
            <NumberInput max={2} min={1} source="status" />
            {/* <RichTextInput source="body" /> */}
            {/* <ReferenceInput
                label="User Id"
                source="user_id"
                reference="users"
            // filter={{ isAdmin: true }}
            >
                <SelectInput optionText="name" />
            </ReferenceInput> */}
            {/* <ReferenceInput
                label="User Ref"
                source="user_ref.___refid"
                reference="users"
            >
                <SelectInput optionText="name" />
            </ReferenceInput> */}
            {/* <FirebaseReferenceInput
                label="User Ref (Firebase)"
                source="user_ref"
                reference="users"
            >
                <SelectInput optionText="name" />
            </FirebaseReferenceInput> */}
            {/* <FileInput source="files_multiple" multiple label="Files with (multiple)">
                <FileField source="src" title="title" />
            </FileInput> */}
            {/* <ArrayInput source="files">
                <SimpleFormIterator>
                    <FileInput source="file" label="Array Form Files">
                        <FileField source="src" title="title" />
                    </FileInput>
                </SimpleFormIterator>
            </ArrayInput> */}
            {/* <ArrayInput source="sections.mySection.items" label="Section items">
                <SimpleFormIterator>
                    <TextInput source="name" label="Name" />
                    <ImageInput source="image" label="Image" accept="image/*">
                        <ImageField source="src" title="title" />
                    </ImageInput>
                </SimpleFormIterator>
            </ArrayInput> */}
        </SimpleForm>
    </Edit>
);